// extracted by mini-css-extract-plugin
export var activePageLink = "Menu-module--activePageLink--91130";
export var activeSlide = "Menu-module--activeSlide--b3529";
export var arrow = "Menu-module--arrow--9e6fb";
export var arrowMenu = "Menu-module--arrowMenu--0f5c6";
export var back = "Menu-module--back--33bf9";
export var current = "Menu-module--current--ba317";
export var extraLink = "Menu-module--extraLink--e7129";
export var extraLinks = "Menu-module--extraLinks--7813e";
export var globalWrapper = "Menu-module--globalWrapper--62b69";
export var linkContainer = "Menu-module--linkContainer--ca1a7";
export var linkContainerWrapper = "Menu-module--linkContainerWrapper--a6c45";
export var menu = "Menu-module--menu--e671f";
export var menuLink = "Menu-module--menuLink--3aad5";
export var menuLinkText = "Menu-module--menuLinkText--deabe";
export var next = "Menu-module--next--d79fa";
export var onlyMobile = "Menu-module--onlyMobile--e9bea";
export var submenu = "Menu-module--submenu--6bd92";
export var submenuLink = "Menu-module--submenuLink--94147";
export var submenuShow = "Menu-module--submenuShow--55a29";
export var text = "Menu-module--text--c8e74";
export var wrapper = "Menu-module--wrapper--57abc";