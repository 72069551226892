import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import * as styles from './Menu.module.css';

import useOutsideAlerter from '../../../hooks/useOutsideAlerter';
import Arrow from '../../../images/arrow-y.inline.svg';
import GlobalWrapper from '../../GlobalWrapper/GlobalWrapper';

export default function Menu({ className, data, onClick, goBack, goNext, sliderIndex }) {
  const [menuActions, setMenuActions] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useState(0);
  const [menuSubSectionIndex, setMenuSubSectionIndex] = useState(1);

  const menuSliderRef = useRef();

  useEffect(() => {
    for(let i = 0; i < data.length; i++) {
      //Checking if the slider's index is part of this menu option
      if(data[i].index + data[i].sections > sliderIndex) {
        setSelectedMenuItem(i);

        if(data[i].submenu) {
          for(let j = 0; j < data[i].submenu.length; j++) {
            if(data[i].submenu[j].index + data[i].submenu[j].sections > sliderIndex) {
              setSelectedSubMenuItem(data[i].submenu[j].index);
              return;
            }
          }
        }
        return;
      }
    };
  }, [data, sliderIndex]);

  useEffect(()=>{
    setMenuActions(
      data.map((item, index) =>
      <NavLink key={ index } item={ item } index={ index } selectedMenuItem={ selectedMenuItem } selectedSubMenuItem={ selectedSubMenuItem } />
      )
    );
  }, [data, selectedMenuItem, selectedSubMenuItem]);


  function NavLink({ item, index, selectedMenuItem, selectedSubMenuItem }) {
    const [active, setActive] = useState(false);
    const [submenuList, setSubmenuList] = useState(null);

    const submenuRef = useRef();
    useOutsideAlerter(submenuRef, () => { setActive(false); });

    //Change menu slider item and triggering main slider update
    useEffect(() => {
      setActive(false);
      handleSliderChange(data[selectedMenuItem].index);
      menuSliderRef.current.slickGoTo(selectedMenuItem);
    }, [selectedMenuItem]);

    useEffect(() => {
      if(item.submenu) {
        setSubmenuList(item.submenu.map((subitem) => subitem.index))
      }
    }, [item]);

    const handleSliderChange = (index, subIndex=null) => {
      setActive(false);
      onClick(index);

      if(subIndex) {
        setMenuSubSectionIndex(subIndex);
      }
    }

    return (
      <div className={ clsx(styles.linkContainer, active && styles.submenuShow, index === selectedMenuItem && styles.current, item.className) } key={ index }>
        {
          item.submenu && item.showSubmenu ?
            <button className={ clsx(styles.menuLink, submenuList && submenuList.includes(index) && styles.activeSlide) } onClick={ () => { setActive(!active) } }>
              <span className={ styles.menuLinkText }>
                <span className={ styles.text }>{ item.text }</span>
                <Arrow className={ styles.arrow }/>
              </span>
            </button>
          :
            <button className={ clsx(styles.menuLink) }
              onClick={ () => {
                setMenuSubSectionIndex(1);
                handleSliderChange(item.index);} }
            >
              <span className={ styles.text }>{ item.text }</span>
            </button>
        }
        {
          //Submenu options
          item.submenu  && item.showSubmenu &&
          <div ref={ submenuRef } className={ styles.submenu }>
            {
              item.submenu.map((subItem, subIndex) =>
              <button key={ subIndex }
                onClick={() => {
                  handleSliderChange(subItem.index, subItem.subIndex);
                }}
                className={ clsx(subItem.className, styles.submenuLink, subItem.index === selectedSubMenuItem && styles.activeSlide) }
              >
                { subItem.text }
              </button>
              )
            }
          </div>
        }
      </div>
    );
  }

  const handleMenuChange = (current, next) => {
    setSelectedMenuItem(next);
    setMenuSubSectionIndex(1);
  }

  const handleArrowClick = (e, type, onClickHandler) => {
    const totalSections = data[selectedMenuItem].sections;

    if(totalSections !== 1) {
      if(type === 'next') {
        if((menuSubSectionIndex + 1) <= totalSections) {
          e.preventDefault();
          setMenuSubSectionIndex(menuSubSectionIndex + 1);
          //Moving slider
          goNext();
        } else {
          //Moving menu
          onClickHandler();
          setSelectedMenuItem(selectedMenuItem + 1);
        }
      } else if(type === 'prev') {
        if((menuSubSectionIndex - 1) > 0) {
          e.preventDefault();
          setMenuSubSectionIndex(menuSubSectionIndex - 1);
          //Moving slider
          goBack();
        } else {
          //Moving menu
          onClickHandler();
          setSelectedMenuItem(selectedMenuItem - 1);
        }
      }
    } else {
      if(onClickHandler) onClickHandler();
    }
  };

  const PrevArrow = ({ className, style, onClick }) =>
    <button className={ clsx(styles.arrowMenu, styles.back, className) }
      style={{ ...style }}
      onClick={ (e) => handleArrowClick(e, 'prev', onClick) }
      aria-label='Back Arrow'
    />;

  const NextArrow = ({ className, style, onClick }) =>
    <button className={ clsx(styles.arrowMenu, styles.next, className) }
      style={{ ...style }}
      onClick={ (e) => handleArrowClick(e, 'next', onClick) }
      aria-label='Next Arrow'
    />;

  return (
    <section className={ clsx('sliderMenu', styles.menu, className) }>
      <GlobalWrapper className={ clsx( styles.globalWrapper, 'mobile-full') }>
        <div className={ styles.wrapper }>
          <div className='desktop:hidden'>
            <Slider ref={ menuSliderRef }
              dots={ false }
              infinite={ false }
              slidesToShow={ 1 }
              slidesToScroll={ 1 }
              arrows={ true }
              beforeChange = { handleMenuChange }
              prevArrow={ <PrevArrow /> }
              nextArrow={ <NextArrow /> }
            >
              {
                menuActions
              }
            </Slider>
          </div>

          <div className='hidden desktop:flex'>
            {
              menuActions
            }
          </div>
        </div>
      </GlobalWrapper>
    </section>
  );
}