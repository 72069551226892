// extracted by mini-css-extract-plugin
export var blueGradient = "loqtorzi-at-a-glance-module--blueGradient--42306";
export var container = "loqtorzi-at-a-glance-module--container--940d8";
export var content = "loqtorzi-at-a-glance-module--content--6040d";
export var image = "loqtorzi-at-a-glance-module--image--3513d";
export var imageHiFive = "loqtorzi-at-a-glance-module--imageHiFive--77b0c";
export var lightBlueBox = "loqtorzi-at-a-glance-module--lightBlueBox--3c012";
export var listNumber = "loqtorzi-at-a-glance-module--listNumber--e7d4c";
export var skewGrayBox = "loqtorzi-at-a-glance-module--skewGrayBox--bf413";
export var subtitle = "loqtorzi-at-a-glance-module--subtitle--5bf71";
export var textAndBottle = "loqtorzi-at-a-glance-module--textAndBottle--9b3a9";
export var title = "loqtorzi-at-a-glance-module--title--87001";
export var titleBig = "loqtorzi-at-a-glance-module--titleBig--22b1e";
export var whiteline = "loqtorzi-at-a-glance-module--whiteline--e729f";
export var wrapper = "loqtorzi-at-a-glance-module--wrapper--18aa2";