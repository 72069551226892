import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';

import * as styles from '../styles/pages/loqtorzi-at-a-glance.module.css';

import Layout from '../components/Layout';
import Carousel from '../components/Carousel/Carousel';
import FirstAndOnlyText from '../components/FirstAndOnlyText/FirstAndOnlyText';
import Footnotes from '../components/Footnotes/Footnotes';
import PinchToEnlarge from '../components/PinchToEnlarge/PinchToEnlarge';
import Banner from '../components/Banner/Banner';
import { TwoColumns, Column } from '../components/TwoColumns/TwoColumns';

import bottleCoupleM from '../images/loqtorzi-at-a-glance/bottle-couple-m.png'
import bottleCouple from '../images/loqtorzi-at-a-glance/bottle-couple.png'

import EfficacyFirstTrialDesignMobile from '../images/efficacy-first/efficacyFirstTrialDesignMobile.inline.svg';
import EfficacyFirstTrialDesignDesktop from '../images/efficacy-first/efficacyFirstTrialDesignDesktop.inline.svg';

import EfficacyReductionMobile from '../images/efficacy-first/efficacy48ReductionMobile.inline.svg';
import EfficacyReductionDesktop from '../images/efficacy-first/efficacy48ReductionDesktop.inline.svg';

import EfficacyFinalDesktop from '../images/loqtorzi-at-a-glance/efficacy-first/efficacyFinalDesktop.inline.svg';
import EfficacyFinalMobile from '../images/loqtorzi-at-a-glance/efficacy-first/efficacyFinalMobile.inline.svg';

import EfficacyFirstSecondaryEndpointDesktop from '../images/efficacy-first/efficacyFirstSecondaryEndpointDesktop.inline.svg';
import EfficacyFirstSecondaryEndpointMobile from '../images/efficacy-first/efficacyFirstSecondaryEndpointMobile.inline.svg';

import EfficacyFirstAchieveResponseDesktop from '../images/efficacy-first/efficacyFirstAchieveResponseDesktop.inline.svg';
import EfficacyFirstAchieveResponseMobile from '../images/efficacy-first/efficacyFirstAchieveResponseMobile.inline.svg';

import SafetyFirstAdverseReactionsTableMobile from '../images/safety-first/safetyFirstAdverseReactionsTableMobile.inline.svg';
import SafetyFirstAdverseReactionsTable from '../images/safety-first/safetyFirstAdverseReactionsTable.inline.svg';

import EfficacySecondaryTrialDesignDesktop from '../images/efficacy-second/efficacySecondaryTrialDesignDesktop.inline.svg';
import EfficacySecondaryTrialDesignMobile from '../images/efficacy-second/efficacySecondaryTrialDesignMobile.inline.svg';

import Efficacy21percent from '../images/efficacy-second/efficacy21percent.inline.svg';
import Efficacymonths from '../images/efficacy-second/efficacymonths.inline.svg';

import SafetySubsequentAdverseReactionsTableMobile from '../images/safety-second/safetySubsequentAdverseReactionsTableMobile.inline.svg';
import SafetySubsequentAdverseReactionsTable from '../images/safety-second/safetySubsequentAdverseReactionsTable.inline.svg';

import DosingFirstLineDesktop from '../images/dosing/dosingFirstLineDesktop.inline.svg';
import DosingFirstLineMobile from '../images/dosing/dosingFirstLineMobile.inline.svg';
import DosingChartMobile from '../images/dosing/dosingChartMobile.inline.svg';
import DosingChart from '../images/dosing/dosingChart.inline.svg';

import DosingSubsequestDesktop from '../images/dosing/dosingSubsequestDesktop.inline.svg';
import DosingSubsequestMobile from '../images/dosing/dosingSubsequestMobile.inline.svg';

import bottleHiFive from '../images/loqtorzi-at-a-glance/bottle-hiFive.png'

import { GtagHead } from '../components/GTAG';
export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>LOQTORZI® (toripalimab-tpzi) at A Glance</title>
      <meta name='description' content='Detail key information on LOQTORZI® on your own'/>
    </>
  )
}

export default function AtAGlance() {

  const data = useCallback(() => [
    {
      menu: 'HOME',
      menuClassName: 'desktop:max-w-[10rem] large:max-w-[13rem]',
      contentClassName: styles.blueGradient,
      contentWrapperClassName: 'desktop:flex desktop:items-end',
      noScroll: true,
      content: [
        <div key='home' className={ styles.textAndBottle }>
          <div className={ styles.content }>
              <FirstAndOnlyText />
              <h1 className={ styles.titleBig }>
                Proven <br className='small:hidden'/>
                efficacy <br/>
                for more <br className='small:hidden'/>
                of <br className='hidden small:block'/>life’s <br className='small:hidden'/>
                moments
              </h1>
              <div className={ clsx(styles.whiteline, 'mt-[1rem]') }></div>
              <div className={ 'relative z-[1] mt-[1rem] font-MontserratMedium text-[1.7rem] leading-[1.2] tracking-[-.02rem] text-white desktop:mt-[1.7rem] large:text-[1.9rem]' }>
              LOQTORZI<sup>®</sup>, in <br className='small:hidden'/>
              combination with <br className='desktop:hidden'/>
              cisplatin and gemcitabine as first-line treatment, <br className='hidden small:block tablet:hidden' />
              delivered significantly <br className='small:hidden' />
              improved progression-free <br className='desktop:hidden'/>
              survival (PFS) vs placebo plus cisplatin and gemcitabine<sup>1</sup>*
              </div>

              <ul className='bullet-list bullet-list--yellow big mt-[1rem] z-[1] font-MontserratRegular text-[1.5rem] !text-white'>
                <li>11.7 months BIRC-assessed median PFS vs 8 months, <br className='hidden small:block tablet:hidden' />respectively (HR=0.52 [95% CI, 0.36-0.74]; <em>P</em>=0.0003)</li>
              </ul>

              <Footnotes className={'relative w-[90%] mt-[2.7rem] z-[1] !text-white !tracking-[-.005rem] large:max-w-[53.9rem]'} items={[
                {
                  bullet: '*',
                  text: <span>Patients were treated for up to 6 cycles, followed by LOQTORZI<sup>®</sup> alone.<sup>1</sup>
                    <br/>BIRC=blinded independent review committee;CI=confidence interval; HR=hazard ratio; R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma.</span>
                }
              ]} />

          </div>
          <div className={ styles.image }>
            <picture>
              <source media="(max-width:767px)" srcSet={ bottleCoupleM } />
              <img src={ bottleCouple } alt={ 'Bottle couple dancing' } />
            </picture>
          </div>
        </div>
      ]
    },
    {
      menu: 'FIRST-LINE TREATMENT',
      menuClassName: 'withSubmenu',
      contentClassName: 'relative z-[1] bg-white',
      subitems: [
        {
          menu: 'TRIAL DESIGN',
          className: 'first-line_treatment_trial_design',
          content: [
            <section className={ styles.wrapper } key='design1'>
              <div className={ styles.container }>
                <span className={ styles.title }>JUPITER-02 trial design</span>
                <div className='mt-[1.1rem] desktop:mt-[1.3rem]'>
                  The efficacy of LOQTORZI<sup>®</sup> in combination with cisplatin and gemcitabine was investigated in JUPITER-02, a randomized, multicenter, single-region,* double-blind, placebo-controlled trial in 289 adult patients with metastatic or recurrent locally advanced NPC who had not received previous systemic chemotherapy for recurrent or metastatic disease.<sup>1†</sup>
                </div>
                <PinchToEnlarge className={ 'mr-[.8rem]' }/>
              </div>
              <EfficacyFirstTrialDesignMobile
                className={ 'w-full max-w-[30.8rem] h-auto mt-[.7rem] mx-auto tablet:hidden' }
              />
              <div className='hidden tablet:block mt-[2.4rem] desktop:py-[1.6rem] desktop:border-y desktop:border-gold-600'>
                <EfficacyFirstTrialDesignDesktop
                  className={ 'w-full h-auto px-[3.7rem]' }
                />
              </div>
              <div className='desktop:pr-[1rem]'>
                <div className={ clsx(styles.container, 'mt-[2.2rem] tablet:mt-[2.6rem] desktop:tracking-normal') }>
                  Treatment with LOQTORZI<sup>®</sup> or placebo continued until disease progression per RECIST v1.1, unacceptable toxicity, or a maximum of 2 years. The primary end point was BIRC-assessed PFS per RECIST v1.1 criteria. The secondary end points were BIRC-assessed ORR, BIRC-assessed DOR, and OS.<sup>1,2</sup>
                </div>
                <div className='desktop:pl-[2.7rem]'>
                  <Banner className={'mt-[1.2rem] mr-[1.2rem] ml-[.7rem] tablet:mt-[2.8rem] desktop:inline-block desktop:text-[2.1rem]'} classNameContainer={ 'desktop:pt-[1.3rem] desktop:pr-[2.5rem] desktop:pb-[1.5rem] desktop:pl-[2rem] desktop:tracking-[.01rem]' } skewDesktop={ true }>
                    <span>
                      LOQTORZI<sup>®</sup>: first and only treatment with a successful registrational trial in R/M NPC that has led to FDA approval<sup>2</sup>
                    </span>
                  </Banner>
                  <div className={ clsx(styles.skewGrayBox, 'mr-[1.2rem] ml-[.7rem]') }>
                    <div>
                      JUPITER-02 is the first and only successful FDA registrational trial out of 6 phase 3 trials studying a PD-1/PD-L1 inhibitor in R/M NPC
                    </div>
                  </div>
                </div>

                <Footnotes className={ clsx(styles.container, 'mt-[2.3rem] pb-[1.2rem] tablet:mt-[2.6rem] pl-[0.5rem] tablet:pb-[3.2rem]') } items={[{
                  bullet: '*',
                  text: <span>The JUPITER-02 trial was conducted across 35 sites in mainland China,
                  Taiwan, and Singapore.<sup>3</sup></span>
                },
                {
                  bullet: <sup>†</sup>,
                  text: <span>Patients with recurrent NPC after treatment with curative intent were required
                  to have an interval of at least 6 months between the last dose of radiotherapy
                  or chemotherapy and recurrence. Patients with autoimmune disease, other
                  than stable hypothyroidism or type 1 diabetes, and patients who required
                  systemic immunosuppression were ineligible.<sup>1</sup>
                  <br/>BIRC=blinded independent review committee; DOR=duration of response;
                  IV=intravenous; ORR=overall response rate; OS=overall survival; PD-1=programmed cell death protein 1;
                  PD-L1=programmed death-ligand 1; PFS=progression-free survival;
                  Q3W=every 3 weeks; R/M NPC=recurrent locally advanced/metastatic
                  nasopharyngeal carcinoma; RECIST=Response Evaluation Criteria in
                  Solid Tumors.</span>
                }]} />
              </div>
            </section>,
            <section className={ styles.wrapper } key='design2'>
              <div className={ styles.container }>
                <span className={ styles.title }>JUPITER-02 trial design</span>
                <div className='mt-[2rem] desktop:flex desktop:gap-[2.9rem]'>
                  <div className='desktop:flex-1'>
                    <div className='font-MontserratBold text-[1.5rem] tracking-normal text-blue-900 desktop:text-[1.3rem] desktop:text-center'>
                      PRIMARY END POINT<sup>1</sup>
                    </div>
                    <div className={ clsx(styles.lightBlueBox, 'mt-[.7rem] min-h-[8.9rem] flex flex-col items-center justify-center') }>
                      <ul className='bullet-list'>
                        <li className='desktop:text-center'>BIRC-assessed progression-free survival <br className='hidden desktop:block'/>per RECIST v1.1 criteria</li>
                      </ul>
                    </div>
                  </div>
                  <div className='mt-[2.1rem] desktop:mt-0 desktop:flex-1'>
                    <div className='font-MontserratBold text-[1.5rem] tracking-normal text-blue-900 desktop:text-[1.3rem] desktop:text-center'>
                      SELECT SECONDARY END POINTS<sup>1</sup>
                    </div>
                    <div className={ clsx(styles.lightBlueBox, 'mt-[.7rem] min-h-[8.9rem] flex flex-col justify-center') }>
                      <ul className='bullet-list normal-spacing justify-center tablet:items-center'>
                        <li>Overall survival</li>
                        <li>BIRC-assessed overall response rate</li>
                        <li>BIRC-assessed duration of response</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='desktop:flex desktop:gap-[2.9rem] desktop:mt-[2.5rem] desktop:py-[1rem] desktop:border-t-[.1rem] desktop:border-gold-600'>
                <div className='mt-[2.5rem] desktop:flex-1 desktop:mt-0'>
                  <h3 className='pb-[1.3rem] border-b-[.2rem] border-gold-600 desktop:border-b-0'>
                    <div className={ clsx(styles.container)}>Key eligibility criteria<sup>3</sup></div>
                  </h3>
                  <ul className={ clsx(styles.container, 'bullet-list normal-spacing bullet-list--yellow big mt-[2rem] desktop:mt-0') }>
                    <li>Primary metastatic or recurrent locally advanced NPC <br className='hidden large:block'/>after curative-intent therapy</li>
                    <li>Treatment naïve for R/M NPC</li>
                    <li>ECOG PS 0-1</li>
                    <li>18-75 years of age</li>
                    <li>Measurable disease per RECIST v1.1</li>
                  </ul>
                </div>

                <div className='mt-[2.5rem] desktop:flex-1 desktop:mt-0'>
                  <h3 className='pb-[1.3rem] border-b-[.2rem] border-gold-600 desktop:border-b-0'>
                    <div className={ clsx(styles.container) }>Key exclusion criteria<sup>3</sup></div>
                  </h3>
                  <ul className={ clsx(styles.container, 'bullet-list normal-spacing bullet-list--yellow big mt-[2rem] desktop:mt-0') }>
                    <li>History of severe hypersensitivity reactions to any monoclonal antibody, cisplatin or gemcitabine, or any ingredient of toripalimab</li>
                    <li>Active or untreated central nervous system metastases</li>
                    <li>Previous monoclonal antibody treatment targeting PD-1/PD-L1/CTLA4</li>
                    <li>History of bone marrow or solid organ transplantation</li>
                    <li>History of protocol-specified autoimmune disease</li>
                  </ul>
                </div>
              </div>

              <div className='mt-[2.5rem] desktop:flex-1 desktop:mt-[3.3rem] desktop:pb-[1.3rem] desktop:border-b-[.1rem] desktop:border-gold-600 large:mt-[9rem]'>
                <h3 className='pb-[1.3rem] border-b-[.2rem] border-gold-600 desktop:border-b-0'>
                  <div className={ clsx(styles.container) }>Key demographics<span><sup>1</sup></span></div>
                </h3>
                <section>
                  <div className={ clsx(styles.container, 'desktop:grid desktop:grid-cols-4 desktop:gap-[2%]') }>
                    <ul className='bullet-list normal-spacing bullet-list--yellow big mt-[2rem] desktop:mt-0'>
                      <li>
                        Median age was 48 years (range: 19 to 72); 4.8% were ≥65 years
                      </li>
                      <li>83% male</li>
                      <li>100% Asian</li>
                    </ul>
                    <ul className='bullet-list normal-spacing bullet-list--yellow big large:w-[20rem] large:pl-[2rem]'>
                      <li>57% ECOG PS of 0</li>
                      <li>86% had metastatic disease</li>
                    </ul>
                    <ul className='bullet-list normal-spacing bullet-list--yellow big'>
                      <li>
                        Histological subtypes:
                        <div>
                          <ul className='bullet-list-dash leading-none'>
                            <li>98% nonkeratinizing</li>
                            <li>1% keratinizing squamous<br />cell carcinoma</li>
                            <li>1% not identified</li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                    <ul className='bullet-list normal-spacing bullet-list--yellow big'>
                      <li>
                        Previous therapy:
                        <div>
                          <ul className='bullet-list-dash'>
                            <li>
                              Approximately 59% received ≥1 prior systemic therapy for locally advanced disease, and 60% received prior radiation therapy
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </section>
              </div>
              <Footnotes className={ clsx(styles.container, 'mt-[2rem] pb-[2rem]')} items={[
                {
                  bullet: '',
                  text: <div>BIRC=blinded independent review committee; CTLA4=cytotoxic
                  T-lymphocyte–associated protein 4; ECOG PS=Eastern Cooperative
                  Oncology Group performance status; PD-1=programmed cell death protein 1; PD-L1=programmed death-ligand 1;
                  R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma;
                  RECIST=Response Evaluation Criteria in Solid Tumors.</div>
                }
              ]} />

            </section>
          ]
        },
        {
          menu: 'EFFICACY',
          className: 'first-line_treatment_efficacy',
          content: [
            <section className={ clsx(styles.wrapper, 'pb-[1.8rem]') } key='eff1'>
              <div className={ styles.container }>
                <div className={ styles.subtitle }>JUPITER-02 efficacy: interim analysis (primary end point)</div>
                <div className={ clsx(styles.title, 'mt-[1rem]') }>LOQTORZI<sup>®</sup> + CHEMO SIGNIFICANTLY <br className='hidden desktop:block'/>REDUCED THE RISK OF PROGRESSION<sup>1</sup></div>
                <div className='font-MontserratRegular mt-[1rem]'>LOQTORZI<sup>®</sup> + chemo delivered a BIRC-assessed mPFS of 11.7 months vs 8 months with placebo + chemo<sup>1,3</sup><sup></sup>*</div>
                <PinchToEnlarge className={ 'mt-[1rem]' } />
                <EfficacyReductionMobile
                  className={clsx('w-full h-auto m-auto tablet:hidden')}
                />
                <EfficacyReductionDesktop
                  className={clsx('hidden w-full h-auto mx-auto tablet:block tablet:mt-[2.4rem]')}
                />
                <Footnotes  className={'mt-[1.5rem] tablet:mt-[1.9rem] desktop:pl-[1.5ex]'} items={[
                  {
                    bullet: '*',
                    text: <span>Cutoff date for interim analysis was May 30, 2020.<sup>1</sup></span>
                  }, {
                    bullet: <sup>†</sup>,
                    text: <span>Based on the stratified Cox proportional-hazards model using the stratification factors at randomization, ECOG performance status, and disease stage.<sup>1</sup></span>
                  },
                  {
                    bullet: <sup>‡</sup>,
                    text: <span>Two-sided <i>P</i> value, based on the stratified log-rank test, as compared with an alpha boundary of 0.010.<sup>1</sup></span>
                  },
                  {
                    bullet: '',
                    text: <span>BIRC=blinded independent review committee; CI=confidence interval; ECOG=Eastern Cooperative Oncology Group; HR=hazard ratio; mPFS=median progression-free survival; NE=not estimable.</span>
                  }
                ]} />
              </div>
            </section>,
            <section className={ clsx(styles.wrapper, 'pb-[1.5rem] tablet:pb-[3.2rem]') } key='eff2'>
              <div className={ styles.container }>
                <div className={ styles.subtitle }>JUPITER-02 efficacy: final analysis</div>
                <div className={ clsx(styles.title, 'mt-[1rem]') }>LOQTORZI<sup>®</sup> + CHEMO OFFERED DURABLE PROTECTION FROM PROGRESSION<sup>4,5</sup>*</div>
                <div className='font-MontserratRegular mt-[1.4rem]'>Final PFS by BIRC per RECIST v1.1<sup>4</sup></div>
                <PinchToEnlarge className={ 'mt-[1rem]' } />

                <EfficacyFinalMobile className={ clsx('w-full h-auto mt-[.6rem] m-auto tablet:hidden') } />
                <EfficacyFinalDesktop className={ clsx('hidden w-full h-auto mt-[1.5rem] mx-auto tablet:block tablet:mt-[2.4rem]') }/>

                <Footnotes  className={'mt-[1.5rem] tablet:mt-[1.9rem] desktop:pl-[1.5ex]'} items={[{
                    bullet: '*',
                    text: <span>Cutoff date for final analysis was June 8, 2021.<sup>4,5</sup></span>
                  },
                  {
                    bullet: <sup>†</sup>,
                    text: <span>Patients were treated with LOQTORZI<sup>®</sup> + cisplatin and gemcitabine for up to six 21-day cycles followed by LOQTORZI<sup>®</sup> monotherapy.<sup>1</sup></span>
                  },
                  {
                    bullet: <sup>‡</sup>,
                    text: <span>Patients were treated with placebo + cisplatin and gemcitabine for up to six 21-day cycles followed by placebo monotherapy.<sup>1</sup></span>
                  },
                  {
                    bullet: '',
                    text: <span>BIRC=blinded independent review committee; CI=confidence interval; mPFS=median progression-free survival; NE=not estimable; RECIST=Response Evaluation Criteria in Solid Tumors.</span>
                  }
                ]} />
              </div>
            </section>,
            <section className={ clsx(styles.wrapper, 'tablet:pb-[3rem]') } key='eff3'>
              <div className={ styles.container }>
                <div className={ styles.subtitle }>JUPITER-02 efficacy: final analysis (secondary end point) and 4-year post hoc analysis</div>
                <div className={ clsx(styles.title, 'mt-[1rem]') }>OS observed with LOQTORZI<sup>®</sup> + chemo vs chemo alone<sup>1,4,6</sup>*</div>
                <div className='font-MontserratMedium mt-[1.4rem]'>Final analysis (secondary end point): LOQTORZI + chemo significantly reduced the risk of progression<sup>1,4&dagger;</sup></div>
                <ul className='bullet-list mt-2'>
                  <li>
                    Median OS not reached with LOQTORZI + chemo (95% CI, 38.7-NE) vs 33.7 months with placebo + chemo (95% CI, 27.0-44.2); <br className='hidden max:block'/>HR=0.63 (95% CI, 0.45-0.89); <em>P</em>=0.0083<sup>&Dagger;</sup>
                    <ul className='bullet-list-dash my-1'>
                      <li>37% reduced risk of death</li>
                    </ul>
                  </li>
                  <li>Number of events was 57/146 (39%) with LOQTORZI + chemo vs 76/143 (53%) with placebo + chemo</li>
                </ul>
                <PinchToEnlarge className={ 'mt-[1rem] !mb-0' } />
                <div className='mt-[2rem] font-MontserratMedium text-center tablet:text-left'>
                  4-year post hoc analysis<sup>6§</sup>
                </div>
                <div className=' mt-1'>This 4-year post hoc analysis was exploratory in nature and occurred after the protocol-specified final analysis.<sup>6</sup></div>

                <EfficacyFirstSecondaryEndpointMobile className={clsx('w-[100%] h-auto m-auto mb-[2rem] tablet:hidden')}/>
                <EfficacyFirstSecondaryEndpointDesktop className={clsx('hidden w-full h-auto m-auto mb-[2rem] tablet:block tablet:mt-[2rem]')}/>


                <ul className='bullet-list'>
                  <li>
                    Median OS not reached with LOQTORZI + chemo (95% CI, 38.8-NE) vs 33.7 months with placebo + chemo<br className='hidden max:block'/>
                    (95% CI, 26.7-44.2); HR=0.61 (95% CI, 0.44-0.85); median follow-up was 36.8 months (range: 0.2-61.1 months)<sup>6</sup>
                    <ul className='bullet-list-dash my-1'>
                      <li>39% reduced risk of death</li>
                    </ul>
                  </li>
                </ul>

                <Footnotes className={'mt-[1.5rem] tablet:mt-[1.9rem] desktop:pl-[1.5ex]'} items={[
                  {
                    bullet: '*',
                    text: <span>Patients were treated with LOQTORZI + cisplatin and gemcitabine or placebo + cisplatin and gemcitabine for up to 6 cycles, followed by LOQTORZI alone or placebo alone.<sup>1</sup></span>
                  },
                  {
                    bullet: <sup>†</sup>,
                    text: <span>Cutoff date for final analysis was November 18, 2022; median follow-up was 36 months.<sup>1</sup></span>
                  },
                  {
                    bullet: <sup>‡</sup>,
                    text: <span>Two-sided <em>P</em> value, based on the stratified log-rank test, as compared with an alpha boundary of 0.049995.<sup>1</sup></span>
                  },
                  {
                    bullet: <sup>§</sup>,
                    text: <span>After the final OS analysis was conducted, as specified by the study protocol, surviving patients were re-consented for long-term survival follow-up. Cutoff date for 4-year follow-up analysis was January 9, 2024, 50 months after the last patient was enrolled.<sup>6</sup></span>
                  },
                  {
                    bullet: '',
                    text: <span>NE=not estimable.</span>
                  }
                ]} />
              </div>
            </section>,
            <section className={ styles.wrapper } key='eff4'>
              <div className={ styles.container }>
                <div className={ styles.subtitle }>JUPITER-02 efficacy: final analysis (secondary end points)</div>
                <div className={ clsx(styles.title, 'mt-[1rem]') }>77% OF PATIENTS TREATED WITH LOQTORZI<sup>®</sup> + CHEMO ACHIEVED A RESPONSE<sup>1</sup></div>
                <div className='font-MontserratRegular mt-[1.4rem]'>BIRC-assessed ORR and DOR</div>
                <PinchToEnlarge className={ 'mt-[1rem]' } />

                <EfficacyFirstAchieveResponseMobile className={clsx('w-full h-auto m-auto tablet:hidden')} />
                <EfficacyFirstAchieveResponseDesktop className={clsx('hidden w-full h-auto m-auto tablet:block tablet:mt-[2rem]')} />
              </div>

              <Banner className={'mt-[2.4rem] mr-[1.4rem] tablet:mt-[2.8rem] desktop:mt-[1.5rem] desktop:mx-[2rem] desktop:text-[2.13rem]'} classNameContainer={ 'desktop:pt-[1.3rem] desktop:pr-[2.5rem] desktop:pb-[1.5rem] desktop:pl-[2rem] desktop:tracking-[.1rem]' } skewDesktop={ true }>
                <span>
                  Nearly 1 in 5 patients experienced a complete response rate with LOQTORZI<sup>®</sup> + chemo<sup>1</sup>
                </span>
              </Banner>

              <div className={ clsx(styles.container, 'pb-[1rem] tablet:pb-[2rem]') }>
                <Footnotes className={'mt-[1.5rem] tablet:mt-[2.8rem] desktop:pl-[1.5ex]'} items={[
                  {
                    bullet: '*',
                    text: <span>Patients were treated with LOQTORZI<sup>®</sup> + cisplatin and gemcitabine for up to six 21-day cycles followed by LOQTORZI<sup>®</sup> monotherapy.<sup>1</sup></span>
                  },
                  {
                    bullet: <sup>†</sup>,
                    text: <span>Patients were treated with placebo + cisplatin and gemcitabine for up to six 21-day cycles followed by placebo monotherapy.<sup>1</sup></span>
                  },
                  {
                    bullet: <sup>‡</sup>,
                    text: <span>ORR results are based on the prespecified interim analysis with data cutoff of May 30, 2020.<sup>1</sup></span>
                  },
                  {
                    bullet: <sup>§</sup>,
                    text: <div>Two-sided <em>P</em> value based upon Cochran-Mantel-Haenszel test.<sup>1</sup><br/>
                    BIRC=blinded independent review committee; CI=confidence interval; DOR=duration of response; NE=not estimable; ORR=overall response rate.</div>
                  }

                ]} />
              </div>
            </section>
          ]
        },
        {
          menu: 'SAFETY',
          className: 'first-line_treatment_safety',
          content: [
            <section className={ clsx(styles.wrapper, 'pb-[2rem]') } key='saf1'>
              <div className={ styles.container }>
                <div className={ styles.subtitle }>JUPITER-02 safety</div>
                <div className={ clsx(styles.title, 'mt-[1rem]') }>LOQTORZI<sup>®</sup> ADVERSE REACTIONS<sup>1</sup></div>
                <div className='font-MontserratRegular mt-[1.4rem]'>Adverse reactions (≥10%) in patients with R/M NPC who received LOQTORZI<sup>®</sup> + chemotherapy in JUPITER-02</div>

                <PinchToEnlarge className={ 'mt-[1rem]' } />
                <SafetyFirstAdverseReactionsTableMobile className={clsx('tablet:hidden w-full h-auto m-auto mt-[.5rem]')} />
                <SafetyFirstAdverseReactionsTable className={clsx('hidden w-full h-auto m-auto mb-[3rem] tablet:block tablet:mt-[1.7rem]')} />

                <div className='mt-[2.5rem] font-MontserratBold text-[1.7rem] text-blue-900'>
                  Serious and fatal adverse reactions
                </div>
                <ul className='bullet-list mt-[1rem] leading-[1.4]'>
                  <li>
                    Serious adverse reactions occurred in 43% of patients receiving LOQTORZI<sup>®</sup> in combination with cisplatin and gemcitabine
                    <ul className='bullet-list-dash leading-[1.3]'>
                      <li>Serious adverse reactions in ≥2% were thrombocytopenia (14%), neutrophil count decreased (10%), pneumonia (10%), anemia (9%), abnormal hepatic function (2.7%), and rash (2.1%)</li>
                    </ul>
                  </li>
                  <li>Of the patients who received LOQTORZI<sup>®</sup> in combination with cisplatin and gemcitabine, there were 3 fatal adverse reactions (2.1%): 1 due to epistaxis; 1 due to intracranial hemorrhage associated with immune-related thrombocytopenia and coagulopathy; and 1 due to pneumonia </li>
                </ul>
                <div className='mt-[2.5rem] font-MontserratBold text-[1.7rem] text-blue-900'>
                  Discontinuations and dosage interruptions
                </div>
                <ul className='bullet-list mt-[1rem] leading-[1.4]'>
                  <li>Permanent discontinuation of LOQTORZI<sup>®</sup>, when given in combination with cisplatin and gemcitabine, due to an adverse reaction occurred in 12% of patients
                    <ul className='bullet-list-dash leading-[1.3]'>
                      <li>Adverse reactions resulting in permanent discontinuation of LOQTORZI<sup>®</sup> in ≥1% of patients were pneumonia (2.1%), pulmonary tuberculosis (1.4%), rash (1.4%), and vomiting (1.4%)</li>
                    </ul>
                  </li>
                  <li>Dosage interruptions of LOQTORZI<sup>®</sup> due to an adverse reaction occurred in 50% of patients
                    <ul className='bullet-list-dash leading-[1.3]'>
                      <li>Adverse reactions which required dosage interruption in ≥2% were anemia (17%), decreased neutrophils (12%), thrombocytopenia (12%), acute kidney injury (4.1%), pneumonia (6%), fatigue (2.7%), upper respiratory infection (2.7%), and hypothyroidism (2.1%)</li>
                    </ul>
                  </li>
                </ul>

                <Footnotes className={'mt-[1.5rem] tablet:mt-[1.9rem] desktop:pl-[1.5ex]'} items={[
                  {
                    bullet: '*',
                    text: <span>National Cancer Institute Common Terminology Criteria for Adverse Events v5.0.<sup>1</sup></span>
                  },
                  {
                    bullet: <sup>†</sup>,
                    text: <span>Patients were treated with LOQTORZI<sup>®</sup> + cisplatin and gemcitabine for up to six 21-day cycles followed by LOQTORZI<sup>®</sup> monotherapy.<sup>1</sup></span>
                  },
                  {
                    bullet: <sup>‡</sup>,
                    text: <span>Patients were treated with placebo + cisplatin and gemcitabine for up to six 21-day cycles followed by placebo monotherapy.<sup>1</sup></span>
                  },
                  {
                    bullet:<sup>§</sup>,
                    text:<span>Includes mouth ulceration, stomatitis, and radiation stomatitis.<sup>1</sup></span>
                  },
                  {
                    bullet:<sup>‖</sup>,
                    text:<span>Includes hypothyroidism, triiodothyronine decreased, triiodothyronine free decreased, and thyroiditis.<sup>1</sup></span>
                  },
                  {
                    bullet:<sup>¶</sup>,
                    text:<span>Includes acneiform dermatitis, allergic dermatitis, catheter-site rash, dermatitis, drug eruption, eczema, erythema, macule, maculopapular rash, palmar-plantar erythrodysesthesia syndrome, papule, pruritic rash, rash, and urticaria.<sup>1</sup></span>
                  },
                  {
                    bullet:<sup>#</sup>,
                    text:<span>Includes asthenia and fatigue.<sup>1</sup></span>
                  },
                  {
                    bullet:'**',
                    text:<span>Includes hypoesthesia, neuralgia, neuropathy peripheral, paresthesia, peripheral sensory neuropathy.<sup>1</sup></span>
                  },
                  {
                    bullet:<sup>††</sup>,
                    text:<span>Includes cough and productive cough.<sup>1</sup></span>
                  },
                  {
                    bullet:<sup>‡‡</sup>,
                    text:<span>Includes back pain, bone pain, musculoskeletal chest pain, musculoskeletal pain, myalgia, neck pain, pain in extremity, pain in jaw.<sup>1</sup></span>
                  },
                  {
                    bullet:<sup>§§</sup>,
                    text:<span>Includes acute sinusitis, bronchitis, laryngitis, nasopharyngitis, pharyngitis, respiratory tract infection, rhinitis, sinusitis, and upper respiratory tract infection.<sup>1</sup></span>
                  },
                  {
                    bullet:<sup>‖‖</sup>,
                    text:<span>Includes aspiration pneumonia and pneumonia.<sup>1</sup></span>
                  },
                  {
                    bullet:<sup>¶¶</sup>,
                    text:<div>Includes blood pressure increased, blood pressure systolic increased, hypertension, and hypertensive crisis.<sup>1</sup><br />
                      R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma.</div>
                  }
                ]} />
              </div>
            </section>
          ]
        }
      ]
    },
    {
      menu: 'SUBSEQUENT TREATMENT',
      menuClassName: 'withSubmenu',
      subitems:[
        {
          menu: 'TRIAL DESIGN',
          className: 'subsequent_treatment_trial_design',
          content: [
            <section className={ styles.wrapper } key='td1'>
              <div className={ styles.container }>
                <span className={ styles.title }>POLARIS-02: Trial design</span>
                <div className='mt-[1.3rem] tablet:mt-[2rem]'>
                  The efficacy of LOQTORZI<sup>®</sup> was studied in POLARIS-02, an open-label, multicenter, multicohort trial conducted in a single country.* The trial included a total of 172 adult patients with unresectable or metastatic NPC who had received prior platinum-based chemotherapy for treatment of recurrent metastatic NPC or who had disease progression within 6 months of completion of platinum-based chemotherapy administered as neoadjuvant, adjuvant, or definitive chemoradiation treatment for locally advanced disease.<sup>1</sup>
                </div>
                <PinchToEnlarge className={ 'mt-[2rem]' } />
                <EfficacySecondaryTrialDesignMobile
                  className={clsx('w-full h-auto m-auto mb-[3rem] tablet:hidden')}
                />
                <EfficacySecondaryTrialDesignDesktop
                  className={clsx('hidden w-[90%] h-auto m-auto mb-[3rem] max-w-[88.2rem] tablet:block tablet:mt-[1.6rem]')}
                />
                <div className='mt-[3.4rem] tablet:[mt-2rem]'>
                  Patients received LOQTORZI<sup>®</sup> 3 mg/kg intravenously every 2 weeks until disease progression per RECIST v1.1 or unacceptable toxicity. <br className='hidden large:block' />The primary efficacy outcomes were confirmed BIRC-assessed ORR and DOR.<sup>1</sup>
                </div>
              </div>
              <div className='desktop:flex desktop:gap-[2.9rem] mt-[3.5rem] desktop:mt-[2.4rem] desktop:py-[1rem] desktop:border-y-[.1rem] desktop:border-gold-600'>
                <div className='mt-[2.5rem] desktop:flex-1 desktop:mt-0'>
                  <h3 className='border-b-[.2rem] border-gold-600 desktop:border-b-0'>
                    <div className={ clsx(styles.container)}>Key eligibility criteria<sup>7</sup></div>
                  </h3>
                  <ul className={ clsx(styles.container, 'bullet-list normal-spacing bullet-list--yellow big mt-[1.5rem]') }>
                    <li>Histologically or cytologically documented R/M NPC <br className='hidden large:block' />refractory to prior standard chemotherapy</li>
                    <li>Disease progression within 6 months after adjuvant <br className='hidden large:block' />chemotherapy or chemoradiotherapy</li>
                    <li>Aged 18 years or older</li>
                    <li>Measurable disease</li>
                    <li>Eastern Cooperative Oncology Group performance status of 0 or 1</li>
                    <li>Adequate organ function</li>
                  </ul>
                </div>

                <div className='mt-[2.5rem] desktop:flex-1 desktop:mt-0'>
                  <h3 className='border-b-[.2rem] border-gold-600 desktop:border-b-0'>
                    <div className={ clsx(styles.container) }>Key exclusion criteria<sup>7</sup></div>
                  </h3>
                  <ul className={ clsx(styles.container, 'bullet-list normal-spacing bullet-list--yellow big mt-[1.5rem]') }>
                    <li>Anticancer monoclonal antibody therapy within 4 weeks <br className='hidden large:block' />before treatment initiation</li>
                    <li>Any anticancer therapy within 2 weeks before treatment initiation</li>
                    <li>Prior ICI treatment</li>
                    <li>Systemic corticosteroid therapy within 7 days before treatment initiation</li>
                    <li>Known additional malignancies</li>
                    <li>Active CNS metastases</li>
                  </ul>
                </div>
              </div>

              <Footnotes className={ clsx(styles.container, 'mt-[2rem] pb-[2rem] desktop:pl-[1.5ex]')} items={[
                {
                  bullet: '*',
                  text: <div>The POLARIS-02 trial was conducted across 17 sites in China.<sup>7</sup></div>
                },
                {
                  bullet: <sup>†</sup>,
                  text: <div>Tumor response assessments were performed every 8 weeks for the first year and every 12 weeks thereafter.<sup>1</sup></div>
                },
                {
                  bullet: '',
                  text: <div>BIRC=blinded independent review committee; CNS=central nervous system; DOR=duration of response; ICI=immune checkpoint inhibitors; ORR=overall response rate; R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma; RECIST=Response Evaluation Criteria in Solid Tumors.</div>
                }
              ]} />

            </section>
          ]
        },
        {
          menu: 'EFFICACY',
          className: 'subsequent_treatment_efficacy',
          content: [
            <section className={ clsx(styles.wrapper, 'pb-[1.5rem]') } key='st-eff1'>
              <div className={ styles.container }>
              <div className={ styles.subtitle }>POLARIS-02 efficacy</div>
                <div className={ clsx(styles.title, 'mt-[1rem]') }>RESPONSE WAS ACHIEVED IN 21% OF PATIENTS WITH LOQTORZI<sup>®</sup><sup>1</sup></div>
                <div className='mt-[1.2rem] font-BarlowCondensedBold text-[2.2rem] leading-none text-blue-500 tablet:mt-[1.7rem] tablet:text-[2.8rem]'>
                  Patients were heavily pretreated, with a median of 2 prior systemic therapies for R/M disease<sup>1,2</sup>*
                </div>
                <div className='mt-[1.9rem] font-MontserratRegular text-center desktop:text-left'>
                  POLARIS-02 efficacy end points: <br className='desktop:hidden'/>confirmed ORR and DOR as assessed by BIRC using RECIST v1.1<sup>1</sup>
                </div>
                <TwoColumns border={ true } className='mt-[1.7rem] mx-[.7rem] py-[4rem] tablet:mt-[2rem] tablet:!py-0 tablet:!border-0'>
                  <Column>
                    <div className='title'>BIRC-assessed ORR<sup>†</sup></div>
                    <Efficacy21percent className='img inline-block !mt-[1.4rem] tablet:!mt-0'></Efficacy21percent>
                    <div className='footnotes desktop:!mt-[.5rem]'>N=172<br />(95% CI, 15%-28%)</div>
                    <div className='bulletsContainer mt-[2.5rem] tablet:mt-[1.2rem]'>
                    <ul className='w-full ml-[1.8rem] bullet-list bullet-list--yellow normal-spacing no-spacing-desktop big text-left tablet:ml-[9%] desktop:ml-[20%] max:ml-[12.9rem]'>
                      <li>Complete response rate: 2.3%</li>
                      <li>Partial response rate: 19%</li>
                    </ul>
                    </div>
                  </Column>
                  <hr className='divider' />
                  <Column>
                    <div className='title'>BIRC-assessed DOR</div>
                    <Efficacymonths className='img inline-block !mt-[1.9rem] tablet:!mt-0'></Efficacymonths>
                    <div className='footnotes desktop:!mt-0'>n=36<br />(95% CI, 10.3%-NE)</div>
                    <div className='bulletsContainer mt-[2.6rem] tablet:mt-[1.2rem]'>
                      <ul className='w-full ml-[1.8rem] bullet-list bullet-list--yellow normal-spacing no-spacing-desktop big text-left tablet:ml-[9%] desktop:ml-[20%] max:ml-[12.9rem]'>
                        <li>Patients with DOR ≥6 months<sup>‡</sup>: 83%</li>
                        <li>Patients with DOR ≥12 months<sup>‡</sup>: 39%</li>
                      </ul>
                    </div>
                  </Column>
                </TwoColumns>
                <Footnotes className={ clsx(styles.container, 'mt-[2rem] desktop:pl-[1.5ex]')} items={[{
                    bullet:'*',
                    text:<span>Range: 1-13.</span>
                  },
                  {
                    bullet: <sup>†</sup>,
                    text: <div>Confirmed ORR assessed by BIRC.<sup>1</sup></div>
                  },
                  {
                    bullet: <sup>‡</sup>,
                    text: <div>Based on observed DOR.<sup>1</sup></div>
                  },
                  {
                    bullet: '',
                    text: <div>BIRC=blinded independent review committee; CI=confidence interval; <br className='tablet:hidden'/>
                      DOR=duration of response; NE=not estimable; ORR=overall response rate; R/M=recurrent/metastatic; <br className='hidden large:block'/>
                      RECIST=Response Evaluation Criteria in Solid Tumors.
                    </div>
                  }
                ]} />
              </div>
            </section>
          ]
        },
        {
          menu: 'SAFETY',
          className: 'subsequent_treatment_safety',
          content: [
            <section className={ clsx(styles.wrapper, 'pb-[2rem]') } key='st-saf-1'>
              <div className={ styles.container }>
                <div className={ styles.subtitle }>POLARIS-02 safety</div>
                <div className={ clsx(styles.title, 'mt-[1rem]') }>LOQTORZI<sup>®</sup> ADVERSE EVENTS<sup>1</sup></div>
                <div className='font-MontserratRegular mt-[1.4rem]'>Adverse reactions (≥10%) in patients with previously treated unresectable or metastatic NPC who received LOQTORZI<sup>®</sup> in POLARIS-02</div>

                <PinchToEnlarge className={ 'mt-[1rem]' } />

                <SafetySubsequentAdverseReactionsTableMobile className={clsx('tablet:hidden w-full h-auto m-auto mt-[1.3rem]')} />
                <SafetySubsequentAdverseReactionsTable className={clsx('hidden w-full h-auto mx-auto mt-[1.5rem] tablet:block')} />
                <div className='mt-[2.5rem] font-MontserratBold text-[1.7rem] text-blue-900'>Serious and fatal adverse reactions</div>
                <ul className='bullet-list mt-[2rem]'>
                  <li>
                    Serious adverse reactions occurred in 24% of patients who received LOQTORZI<sup>®</sup>
                    <ul className='bullet-list-dash leading-[1.3]'>
                      <li>Serious adverse drug reactions in ≥2% were pneumonia (4.7%), abnormal hepatic function (2.6%), and hyperbilirubinemia (2.1%)</li>
                    </ul>
                  </li>
                  <li>Fatal adverse reactions occurred in 3.7% of patients who received LOQTORZI<sup>®</sup>, including death not otherwise specified (1.6%), tumor hemorrhage (0.5%), hepatic failure and thrombocytopenia (0.5), hyponatremia (0.5%), and sudden death (0.5%)</li>
                </ul>
                <div className='mt-[2.5rem] font-MontserratBold text-[1.7rem] text-blue-900'>Discontinuations and dosage interruptions</div>
                <ul className='bullet-list big mt-[1.4rem]'>
                  <li>
                    Permanent discontinuation of LOQTORZI<sup>®</sup> due to an adverse reaction occurred in 9% of patients
                    <ul className='bullet-list-dash'>
                      <li>Adverse reactions resulting in permanent discontinuation of LOQTORZI<sup>®</sup> in ≥1% of patients were pneumonia (1.1%), abnormal hepatic function (1.1%), and hyperbilirubinemia (1.1%)</li>
                    </ul>
                  </li>
                  <li>
                    Dosage interruptions due to an adverse reaction occurred in 23% of patients
                    <ul className='bullet-list-dash'>
                      <li>Adverse reactions which required dosage interruptions in ≥1% were pneumonia (2.1%), thrombocytopenia (2.1%), fatigue (1.6%), hyperbilirubinemia (1.6%), anemia (1.1%), decreased appetite (1.1%), abnormal hepatic function (1.1%), hypothyroidism (1.1%), and pneumonitis (1.1%)</li>
                    </ul>
                  </li>
                </ul>

                <Footnotes className={'mt-[1.5rem] tablet:mt-[2.6rem] desktop:pl-[1.5ex]'} items={[
                  {
                    bullet: '*',
                    text: <span>Toxicity was graded per NCI CTCAE v4.03.<sup>1</sup></span>
                  },
                  {
                    bullet: <sup>†</sup>,
                    text: <span>Includes hypothyroidism, thyroiditis, triiodothyronine decreased, and triiodothyronine free decreased.<sup>1</sup></span>
                  },
                  {
                    bullet: <sup>‡</sup>,
                    text: <span>Includes fatigue and asthenia.<sup>1</sup></span>
                  },
                  {
                    bullet: <sup>§</sup>,
                    text: <span>Includes cough and productive cough.<sup>1</sup></span>
                  },
                  {
                    bullet:<sup>||</sup>,
                    text:<span>Includes musculoskeletal pain and myalgia.<sup>1</sup></span>
                  },
                  {
                    bullet:<sup>¶</sup>,
                    text:<span>Includes dermatitis allergic, eczema, and rash.<sup>1</sup></span>
                  }
                ]} />
              </div>
            </section>
          ]
        }
      ]
    },
    {
      menu: 'DOSING & ADMINISTRATION',
      showSubmenu: false,
      contentClassName: 'relative z-[1] bg-white',
      subitems: [
        {
          menu: null,
          content: [
            <section className={ clsx(styles.wrapper, 'pb-[2rem] desktop:pl-[.5rem]') } key='da-first'>
              <div className={ styles.container }>
                <div className={ clsx(styles.title) }>
                  Dosing and administration for first-line TREATMENT<sup>1</sup>
                </div>
              </div>
              <div className='mt-[2.5rem]'>
                <h3 className='pb-[.8rem] border-b-[.2rem] border-gold-600 tablet:border-b-0 tablet:text-[2.8rem]'>
                  <div className={ clsx(styles.container)}>Recommended dosage</div>
                </h3>
              </div>
              <DosingChartMobile className={'w-full m-auto h-auto mt-[1.8rem] mb-[2rem] tablet:hidden'}/>
              <DosingChart className={'hidden w-full m-auto h-auto mb-[2rem] tablet:block'}/>

              <Footnotes className={'mt-[2.1rem] pl-[.5rem] tablet:mt-[3rem] tablet:pl-0'} items={[
                  {
                    bullet: '*',
                    text: <span>LOQTORZI<sup>®</sup> is indicated, in combination with cisplatin and gemcitabine, for the first-line treatment of adults with metastatic or with recurrent, locally advanced NPC. Refer to the Prescribing Information for cisplatin and gemcitabine for recommended dosing information.</span>
                  }
                ]} />
              <div className='mt-[2.5rem]'>
                <h3 className='pb-[.8rem] border-b-[.2rem] border-gold-600 tablet:pb-0 tablet:border-b-0 tablet:text-[2.8rem]'>
                  <div className={ clsx(styles.container)}>In JUPITER-02, LOQTORZI<sup>®</sup> was administered:</div>
                </h3>
              </div>
              <div className={ styles.container }>
                <DosingFirstLineMobile className={'w-full m-auto h-auto mt-[1.8rem] tablet:hidden'} />
                <DosingFirstLineDesktop className={'hidden w-full h-auto m-auto mt-[.9rem] tablet:block'} />
              </div>
              <div className='mt-[2.5rem]'>
                <h3 className='pb-[.8rem] border-b-[.2rem] border-gold-600 tablet:pb-0 tablet:border-b-0 tablet:text-[2.8rem]'>
                  <div className={ styles.container }>Administration</div>
                </h3>
              </div>
              <div className={ styles.container }>
                <ul className='bullet-list big mt-[1.3rem]'>
                  <li>LOQTORZI<sup>®</sup> IV infusion details:
                    <ul className='bullet-list-dash mt-[.5em] leading-[1.3]'>
                      <li>Administer diluted solution intravenously via infusion pump using an in-line aseptic filter (0.2 or 0.22 micron)</li>
                      <li>First infusion: Infuse over at least 60 minutes</li>
                      <li>Subsequent infusions: If no infusion-related reactions occurred during the first infusion, subsequent infusions may be administered over 30 minutes</li>
                      <li>Do not co-administer other drugs through the same IV line</li>
                      <li>When administered on the same day as chemotherapy, LOQTORZI<sup>®</sup> should be administered prior to chemotherapy</li>
                      <li>Refer to the Prescribing Information for cisplatin and gemcitabine for recommended dosing information</li>
                    </ul>
                  </li>
                </ul>

                <Footnotes className={'mt-[2.1rem] tablet:mt-[3.9rem]'} items={[
                  {
                    bullet: '',
                    text: <span>IV=intravenous; NPC=nasopharyngeal carcinoma.</span>
                  }
                ]} />
              </div>
            </section>,
            <section className={ clsx(styles.wrapper, 'pb-[2rem]') } key='da-second'>
              <div className={ styles.container }>
                <div className={ clsx(styles.title) }>
                  Dosing and administration for subsequent treatment<sup>1</sup>
                </div>
              </div>
              <h3 className='mt-[2.5rem] pb-[.8rem] border-b-[.2rem] border-gold-600 tablet:mt-[1.2rem] tablet:pb-0 tablet:border-b-0 tablet:text-[2.8rem]'>
                <div className={ clsx(styles.container)}>Dosing</div>
              </h3>
              <div className={ styles.container }>
                <DosingSubsequestMobile className={'tablet:hidden w-full h-auto m-auto mt-[2rem]'} />
                <DosingSubsequestDesktop className={'hidden tablet:block w-full h-auto m-auto mt-[2rem]'} />
                <div className='mt-[2.7rem] tablet:mt-[1.5rem]'>
                  Patients should receive LOQTORZI<sup>®</sup> until disease progression or unacceptable toxicity.
                </div>
              </div>

              <div className='mt-[2.5rem]'>
                <h3 className='pb-[.8rem] border-b-[.2rem] border-gold-600 tablet:pb-0 tablet:border-b-0 tablet:text-[2.8rem]'>
                  <div className={ styles.container }>Administration</div>
                </h3>
              </div>
              <div className={ styles.container }>
                <ul className={ clsx(styles.container, 'bullet-list normal-spacing bullet-list--yellow big mt-[2rem]') }>
                  <li>First infusion: Infuse over at least 60 minutes</li>
                  <li>Subsequent infusions: If no infusion-related reactions occurred during the first infusion, subsequent infusions may be administered over 30 minutes</li>
                  <li>Do not co-administer other drugs through the same IV line</li>
                </ul>

                <Footnotes className={'mt-[2.1rem] tablet:mt-[1.7rem]'} items={[
                  {
                    bullet: '',
                    text: <span>IV=intravenous.</span>
                  }
                ]} />
              </div>
            </section>
          ]
        }
      ]
    },
    {
      menu: 'SUMMARY',
      menuClassName: 'desktop:max-w-[10rem] large:max-w-[14rem]',
      contentClassName: styles.blueGradient,
      noScroll: true,
      content: [
        <div className={ clsx(styles.textAndBottle, 'pt-[1.25rem] pl-0 tablet:relative tablet:!pr-0 large:pb-[3.8rem] max:pt-[5rem]') } key='summ'>
          <div className={ clsx(styles.content, 'relative z-[1] tablet:static') }>
            <div className={ styles.container}>
              <FirstAndOnlyText />
              <h1 className={ clsx(styles.titleBig, 'mt-[1.26rem]') }>
                Proven efficacy <br/>
                for more of <br />
                life’s moments<sup><strong>1</strong></sup>*
              </h1>
              <ul className='bullet-list bullet-list--yellow big z-[1] mt-[2.4rem] font-MontserratRegular tracking-[-.05rem] leading-[1.2] text-white tablet:mt-[2rem]'>
                <li>Significantly improved <br className='small:hidden'/>progression-free survival (PFS) and overall survival (OS) vs <br className='tablet:hidden'/>placebo + chemo<sup>1†</sup></li>
                <li>Manageable safety profile<sup>1</sup></li>
                <li>The first successful <br className='small:hidden'/>registrational trial in R/M <br className='tablet:hidden'/>NPC that led to FDA <br className='small:hidden'/>approval<sup>2</sup></li>
              </ul>
            </div>
            <Banner className={'z-[1] mt-[5.25rem] mr-[1.2rem] text-[1.8rem] tablet:mt-[2.8rem] desktop:inline-block desktop:max-w-[71.5rem] large:ml-[-3rem]'} classNameContainer={ 'pt-[1.8rem] pb-[2rem] desktop:pt-[1.3rem] desktop:pr-[2.5rem] desktop:pb-[1.5rem] desktop:pl-[2rem] desktop:tracking-[.01rem]' } skewDesktop={ true }>
              <span>
                LOQTORZI<sup>®</sup>: the first and only FDA-approved first-line treatment for patients with R/M NPC to deliver a statistically significant improvement over the current standard of&nbsp;care<sup>1</sup>*<sup>†</sup>
              </span>
            </Banner>

            <div className={ clsx(styles.container, 'tablet:h-[7rem] large:h-[8.3rem]')}>
              <Footnotes className={'relative w-[90%] mt-[2.5rem] z-[1] !text-white !tracking-[-.005rem] tablet:absolute tablet:left-0 desktop:mt-[3rem] desktop:pl-[1.5ex] large:mt-[5rem]'} items={[
                {
                  bullet: '*',
                  text: <span>In combination with cisplatin and gemcitabine for up to 6 cycles followed by LOQTORZI<sup>®</sup> alone.<sup>1</sup></span>
                },
                {
                  bullet: <sup>†</sup>,
                  text: <span>LOQTORZI<sup>®</sup> improved PFS (HR=0.52 [95% CI, 0.36-0.74]; <em>P</em>=0.0003) and OS (HR=0.63 [95% CI, 0.45-0.89]; <em>P</em>=0.0083) when used in combination with cisplatin and gemcitabine for up to 6 cycles followed by LOQTORZI<sup>®</sup> alone.<sup>1</sup></span>
                },
                {
                  bullet: '',
                  text: <span>CI=confidence interval; HR=hazard ratio; R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma.</span>
                }
              ]} />
            </div>
          </div>
          <div className={ styles.imageHiFive }>
            <img src={ bottleHiFive } alt={ 'Bottle Hi Five' } />
          </div>
        </div>
      ]
    },
    {
      menu: 'REFERENCES',
      menuClassName: 'desktop:max-w-[10rem] large:max-w-[15rem]',
      contentClassName: styles.blueGradient,
      content: [
        <section className={ clsx(styles.wrapper, 'pb-[3rem] tablet:pt-[3rem]') } key='ref'>
          <div className={ clsx(styles.container, 'text-[1.2rem] text-white') }>
            <div className='font-MontserratMedium'>References</div>
            <ol className= { styles.listNumber }>
              <li>LOQTORZI<sup>®</sup> (toripalimab-tpzi) Prescribing Information. Redwood City, CA: Coherus BioSciences, Inc.</li>
              <li>ClinicalTrials.gov. 55 studies found for: recurrent, metastatic | nasopharyngeal carcinoma. Accessed September 28, 2023. <span className='break-all'>https://clinicaltrials.gov/ct2/results?term=Recurrent%2C+Metastatic&cond=Nasopharyngeal+Carcinoma&age_v=&gndr=&type=&rslt=&phase=2&Search=Apply</span></li>
              <li>Mai H-Q, Chen Q-Y, Chen D, et al. Toripalimab or placebo plus chemotherapy as first-line treatment in advanced nasopharyngeal carcinoma: a multicenter randomized phase 3 trial. <em>Nat Med</em>. 2021;27(9):1536-1543. doi:10.1038/s41591-021-01444-0</li>
              <li>Data on file. Coherus BioSciences, Inc.; 2023.</li>
              <li>Mai H-Q, Chen Q-Y, Chen D, et al. Toripalimab plus chemotherapy for recurrent or metastatic nasopharyngeal carcinoma: the JUPITER-02 randomized clinical trial. <em>JAMA</em>. 2023;330(20):1961-1970. doi:10.1001/jama.2023.20181</li>
              <li>Chen Q-Y, Mai H-Q, Chen D, et al. Four-year overall survival follow-up and dynamic EBV titer analysis of toripalimab versus placebo in combination with gemcitabine and cisplatin as first-line treatment for recurrent or metastatic nasopharyngeal carcinoma (r/m NPC). Abstract presented at: 2024 American Society of Clinical Oncology Annual Meeting; May 31-June 4, 2024; Chicago, IL.</li>
              <li>Wang F-H, Wei X-L, Feng J, et al. Efficacy, safety, and correlative biomarkers of toripalimab in previously treated recurrent or metastatic nasopharyngeal carcinoma: a phase II clinical trial (POLARIS-02). <em>J Clin Oncol</em>. 2021;39(7):704-712. doi:10.1200/JCO.20.02712</li>
            </ol>
          </div>
        </section>
      ]
    }
  ], []);

  //Preparing menu data
  const menu = useMemo(() => {
    let count = 0;
    return data().map((item) => {
      let result = {
        text: item.menu,
        className: item.menuClassName,
        sections: 0,
        showSubmenu: item.showSubmenu === false ? false : true
      };

      if(!item.subitems) {
        result.index = count++;
        result.sections = item.content.length;
      }
      else {
        result.index = count;
        let subIndex = 1;
        result.submenu = item.subitems.map((subitem) => {
          let sub = {
            text: subitem.menu,
            className: subitem.className,
            index: count++ ,
            subIndex: subIndex
          };

          if(subitem.content) {
            result.sections += subitem.content.length;
            sub.sections = subitem.content.length;
            if(subitem.content.length !== 1) {
              count += subitem.content.length - 1;
              subIndex += subitem.content.length;
            } else {
              subIndex++;
            }
          }

          return sub;
        });
      }
      return result;
    });
  }, [data]);

  //Preparing main slider content data
  const content = useMemo(() => {
    let result = [];

    const addGlobalElement = (el, className, wrapperClassName, noScroll=false) => {
      return el.map((item) => {
        return {
          content: item,
          className: className,
          wrapperClassName: wrapperClassName,
          noScroll: noScroll
        }
      })
    };

    data().forEach((item) => {
      if(item.content) {
        result = [].concat(result, addGlobalElement(item.content, item.contentClassName, item.contentWrapperClassName, item.noScroll));
      }
      if(item.subitems) {
        //Extract all subitems as main slides
        const subitemSlides = item.subitems.map((subitem) => addGlobalElement(subitem.content, item.contentClassName, item.contentWrapperClassName, item.noScroll));
        result = [].concat(result, ...subitemSlides);
      }
    });

    return result;
  }, [data]);

  return (
    <Layout showNavBanner={ false }>
      <Carousel menu={ menu } slides={ content } />
    </Layout>
  );
}