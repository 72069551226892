
import React from 'react';
import clsx from 'clsx';
import * as styles from './PinchToEnlarge.module.css';
import EnlargeIcon from '../../images/enlargeIcon.inline.svg';

export default function PinchToEnlarge({ className }) {
  return (
    <section className={ clsx(className, styles.pinch) }>
      <span>PINCH TO ENLARGE</span>
      <EnlargeIcon></EnlargeIcon>
    </section>
  );
}