import React from 'react';
import clsx from 'clsx';
import * as styles from './FirstAndOnlyText.module.css';
import HeadStar from '../../images/loqtorzi-at-a-glance/headStar.inline.svg';

export default function FirstAndOnlyText({ className }) {
  return (
    <section className={ clsx(styles.component, className) }>
      <div>
        <span className={ 'text-gold-600' }>First and only FDA-approved</span> treatment for patients with recurrent locally advanced/metastatic
        nasopharyngeal carcinoma (R/M NPC)<sup>1</sup>
      </div>
      <div className={ styles.image }>
        <HeadStar />
      </div>
    </section>
  );
}