import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import useIsiStore from '../../hooks/useIsiStore';

import * as styles from './Carousel.module.css';

import Menu from './Menu/Menu';
import GlobalWrapper from '../GlobalWrapper/GlobalWrapper';

export default function Carousel({ className, slides, menu }) {
  const recalculateTrayPos = useIsiStore((state) => state.recalculateTrayPos);

  const [current, setCurrent] = useState(0);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(true);

  const carouselRef = useRef();

  useEffect(() => {
    setHasPrev(current-1 >= 0);
    setHasNext(current+1 <= slides.length)
  }, [current, slides]);

  const goBack = () => {
    carouselRef.current.slickPrev();
  }

  const goNext = () => {
    carouselRef.current.slickNext();
  }

  const goTo = (index) => {
    carouselRef.current.slickGoTo(index);
  }

  const PrevArrow = ({ className, style, onClick }) =>
    <button
      className={ clsx(styles.control, styles.back, className) }
      style={{ ...style }}
      onClick={ onClick }
      disabled={ !hasPrev }
      aria-label='Back Arrow'
    />
  ;

  const NextArrow = ({ className, style, onClick }) =>
    <button className={ clsx(styles.control, styles.next, className) }
      style={{ ...style }}
      onClick={ onClick }
      disabled={ !hasNext }
      aria-label='Next Arrow'
    />

  return (
    <div className={ clsx(styles.carousel, className) }>
      <Menu data={ menu } onClick={ goTo } goNext={ goNext } goBack={ goBack } sliderIndex={ current }/>

      <Slider ref={ carouselRef } className={'sliderCarousel'}
        dots={ false }
        infinite={ false }
        slidesToShow={ 1 }
        slidesToScroll={ 1 }
        arrows={ true }
        adaptiveHeight={ true }
        beforeChange={ (current, next) => { setCurrent(next); recalculateTrayPos(); } }
        nextArrow= { <NextArrow /> }
        prevArrow= { <PrevArrow /> }
      >
        {
          slides.map((item, index) =>
          <section className={ item.className } key={ index }>
            <GlobalWrapper className={ clsx('desktop:relative desktop:pr-[4rem] desktop:pl-[6.4rem]', item.wrapperClassName) } showBorderLeftMobile={true}>
              <div className={ clsx(styles.carouselGlobalWrapper, !item.noScroll && 'desktop:overflow-auto') }>
                { item.content }
              </div>
            </GlobalWrapper>
          </section>
          )
        }
      </Slider>
    </div>
  );
}